declare class JQuery<TElement = HTMLElement> {
  selectric(): void;
  slick(params?:any): void;
  flickity(params?:any): void;
}

declare const Gumshoe: any;

document.addEventListener('setupCompleted', () => {
  // Menu BTN
  const btn = document.getElementById("menu_btn");
  if ( btn ) {
    btn.addEventListener("click", function() {
        btn.classList.toggle("active");
        const nav = document.querySelector(".site-nav")
        if (nav) nav.classList.toggle("active");
    });
  }

  var header = document.querySelector('#main-nav');
  var spy = new Gumshoe('.spy-nav a',{
    nested: true,
    nestedClass: 'active',
    offset: function () {
      return header? header.getBoundingClientRect().height : 0;
    }
  });
});

// FAQ TABS
// window.addEventListener("load", function() {
  
// });

(function(){
  if( $(window).width()! > 1){
    $(window).scroll(function() {    
        var scroll = $(window).scrollTop() || 0;
        if (scroll >= 50) {
            $("body").addClass("nav-scrolled");
        } else {
            $("body").removeClass("nav-scrolled");
        }
    });
  }

  // ###################################### Focus search inputs for mobile ###################################
  const elm = document.getElementById("addressSearchInput_mobile");
  if (elm) elm.addEventListener("focus", function(){
    $(this).parent().addClass('mobile_active');
  });

  $('.closer').click(function() {
    $('.search-form').removeClass('mobile_active');
  });

  // ########################################### Home carousel ###################################################
  const arrowShape = 'm 43.0918 109.3635 c 0.52 -7.02 -1.04 -13.845 -4.68 -20.475 c -3.64 -6.63 -8.905 -12.48 -15.795 -17.55 c -6.76 -5.2 -14.3 -8.97 -22.62 -11.31 l 0 -9.9075 c 8.32 -2.3399 15.86 -6.0449 22.62 -11.1149 c 6.89 -5.2 12.155 -11.115 15.795 -17.745 c 3.64 -6.63 5.2 -13.455 4.68 -20.4749 l 6.4128 2.535 c 1.6898 1.04 2.8598 2.21 3.5099 3.51 c 0.65 1.17 0.845 2.73 0.585 4.68 c -0.91 4.68 -3.25 9.815 -7.0198 15.405 c -3.77 5.59 -8.58 10.53 -14.43 14.82 c -5.85 4.29 -12.155 7.085 -18.915 8.3849 l 116.7653 0 l 0 9.9075 l -116.7653 0 c 6.76 1.3 13.065 4.095 18.915 8.385 c 5.85 4.29 10.66 9.23 14.43 14.82 c 3.7698 5.59 6.1098 10.725 7.0198 15.405 l 0.195 1.56 c 0 1.43 -0.39 2.665 -1.17 3.705 c -0.65 1.04 -1.69 2.015 -3.1199 2.925 l -6.4128 2.535 z';
  $('.restaurant-carousel').flickity({
    cellAlign: 'left',
    contain: true,
    // adaptiveHeight: true,
    // draggable: '>1',
    // dragThreshold: 3,
    // freeScroll: false,
    // friction: 0.1, // smaller number = easier to flick farther
    // groupCells: false,
    // lazyLoad: true, // set img data-flickity-lazyload="src.jpg" / set to number to load images adjacent cells
    // percentPosition: true,
    // prevNextButtons: true,
    pageDots: false,
    // resize: true,
    // wrapAround: false
    arrowShape: arrowShape
  });

  // ############################################## SLIDES #######################################################
  // Greeting headers
  $(".partner-list-main").slick({
    slidesToShow: 9,
    slidesToScroll: 1,
    rows: 2,
    autoplay: true,
    autoplaySpeed: 0,
    arrows: false,
    dots: false,
    speed: 1200,
    infinite: true,
    pauseOnHover: false,
    responsive: [
    {
        breakpoint: 1500,
        settings: {
            slidesToShow: 7
        }
    },
    {
        breakpoint: 968,
        settings: {
            slidesToShow: 6
        }
    }, {
        breakpoint: 768,
        settings: {
            slidesToShow: 4
        }
    }, {
        breakpoint: 520,
        settings: {
            slidesToShow: 2
        }
    }]
  });


  // ############################################## SLIDES #######################################################
  // Shows app slides, should be 2, one to controll titles as nav for slides and vise versa

  // Headers
  $('.appshow-caro-header').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.appshow-caro-body'
  });
  // App Body screens
  $('.appshow-caro-body').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.appshow-caro-header',
    dots: true,
    arrows: false,
    focusOnSelect: true,
    autoplay: true
  });

  // ############################################## SLIDES #######################################################
  // Shows the Background per restaurant slider
  $('.restaurant-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.restaurant-nav'
  });


  $('.item-logo').on('click', function(event){
    event.preventDefault();
  });


  // Shows the restaurant logo as nav for above.
  $('.restaurant-nav').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.restaurant-slider, .restaurant-description',
    dots: false,
    arrows: false,
    centerMode: true,
    focusOnSelect: true,
    autoplay: false,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          centerPadding: '300px',
        }
      },
      {
        breakpoint: 1660,
        settings: {
          centerPadding: '210px',
        }
      },
      {
        breakpoint: 1440,
        settings: {
          centerPadding: '200px',
        }
      },
      {
        breakpoint: 1200,
        settings: {
          centerPadding: '120px',
        }
      },
      {
        breakpoint: 1024,
        settings: {
          centerPadding: '60px',
        }
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: '80px',
        }
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: '100px',
        }
      },
      {
        breakpoint: 460,
        settings: {
          centerPadding: '100px',
        }
      },
      {
        breakpoint: 400,
        settings: {
          centerPadding: '80px',
        }
      },
      {
        breakpoint: 330,
        settings: {
          centerPadding: '60px',
        }
      }

    ]
  });
  $('.restaurant-nav').on('afterChange', function(slick, currentSlide){
      var imageUrl = $('.slick-active .item-main').data('itembackground');

    $('.restaurant-nav-main').css('background-image', 'url("' + imageUrl + '")');
  });
  
  var imageUrl = $('.restaurant-nav .slick-active .item-main').data('itembackground');
  $('.restaurant-nav-main').css('background-image', 'url("' + imageUrl + '")');



  $('.restaurant-nav a').click(function(e) {
    if(!$(this).parent().parent().parent().hasClass('slick-active')){
         e.preventDefault();
     }
  });


  $('.restaurant-description').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.restaurant-nav, .restaurant-slider'
  });

   // ############################################## SLIDES #######################################################
  // Shows Careers `TEAM` carousel, should be 2, one for bg one for description

  // Headers
  $('.career-team-bg').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.career-team-desc'
  });

  // Shows the restaurant logo as nav for above.
  $('.career-team-desc').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.career-team-bg',
    dots: true,
    arrows: false,
    centerMode: true,
    focusOnSelect: true,
    autoplay: false,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          centerMode: true,
          centerPadding: '300px',
        }
      },
      {
        breakpoint: 1440,
        settings: {
          centerMode: true,
          centerPadding: '80px',
        }
      },
      {
        breakpoint: 420,
        settings: {
          centerMode: false,
          centerPadding: '0',
        }
      }
    ]
  });

  // ############################################## SLIDES #######################################################
  // Greeting headers
  $(".partner-testimonials").slick({
    arrows: false,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    infinite: true,
    fade: true,
    adaptiveHeight: true
  });

   // ############################################## SLIDES #######################################################
  // Greeting headers
  $(".driver-testimonials").slick({
    arrows: false,
    dots: true,
    slidesToScroll: 1,
    autoplay: false,
    infinite: true,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1
        }
      }
    ]
    
  });

  // ############################################## SLIDES #######################################################
  $(window).resize(function(){
    var divHeight = $('.careers-carousel').height(); 
    $('.career-team-bg-main').css('min-height', divHeight+'px');
  });

  $(document).ready(function() {
    var divHeight = $('.careers-carousel').height(); 
    $('.career-team-bg-main').css('min-height', divHeight+'px');
  });
  $(document).ready(function() {
    var divHeight = $('.restaurant-nav-main').height(); 
    $('.restaurant-header').css('min-height', divHeight+'px');
  }); 

  $('.location-title').on('click', function(){
      $(this).parent().toggleClass('active');
  });

  $('.terms-title').on('click', function(event){
    event.preventDefault();
    $(this).parent().toggleClass('active');
  });

  $("#show-step1").click(function (event) {
    event.preventDefault()
    $("#step1").show();
    
    $("#step2").hide();
    $("#step3").hide();
  });

  $("#show-step2").click(function (event) {
    event.preventDefault()
    $("#step2").show();
    
    $("#step1").hide();
    $("#step3").hide();
  });

  $("#show-step3").click(function (event) {
    event.preventDefault()
    $("#step3").show();
    
    $("#step1").hide();
    $("#step2").hide();
  });

  $(function() {
    $('select')?.selectric();
  });

})();